@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-Light.eot');
    src: url('fonts/Freshperts/Freshperts-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-Light.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-Light.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-Bold.eot');
    src: url('fonts/Freshperts/Freshperts-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-Bold.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-Bold.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-UltraLightItalic.eot');
    src: url('fonts/Freshperts/Freshperts-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-UltraLightItalic.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-UltraLightItalic.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-MediumItalic.eot');
    src: url('fonts/Freshperts/Freshperts-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-MediumItalic.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-MediumItalic.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-ThinItalic.eot');
    src: url('fonts/Freshperts/Freshperts-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-ThinItalic.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-ThinItalic.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-LightItalic.eot');
    src: url('fonts/Freshperts/Freshperts-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-LightItalic.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-LightItalic.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-UltraBoldItalic.eot');
    src: url('fonts/Freshperts/Freshperts-UltraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-UltraBoldItalic.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-UltraBoldItalic.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-UltraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-Regular.eot');
    src: url('fonts/Freshperts/Freshperts-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-Regular.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-Regular.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-BoldItalic.eot');
    src: url('fonts/Freshperts/Freshperts-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-BoldItalic.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-BoldItalic.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-Medium.eot');
    src: url('fonts/Freshperts/Freshperts-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-Medium.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-Medium.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-UltraBold.eot');
    src: url('fonts/Freshperts/Freshperts-UltraBold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-UltraBold.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-UltraBold.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-UltraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-Thin.eot');
    src: url('fonts/Freshperts/Freshperts-Thin.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-Thin.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-Thin.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-RegularItalic.eot');
    src: url('fonts/Freshperts/Freshperts-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-RegularItalic.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-RegularItalic.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Freshperts';
    src: url('fonts/Freshperts/Freshperts-UltraLight.eot');
    src: url('fonts/Freshperts/Freshperts-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('fonts/Freshperts/Freshperts-UltraLight.woff2') format('woff2'),
    url('fonts/Freshperts/Freshperts-UltraLight.woff') format('woff'),
    url('fonts/Freshperts/Freshperts-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

.header-user {
    font-size: 17px;
}
.user-header-photo {
    border: 1px solid #CFCFCF;
}
.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.userAddress{
    border: 1px solid rgba(0,0,0,.06);
}

.userAddress:hover {
    background-color: #f7f7f7;
}

.daysOfWeek:hover {
    border: 1px solid #7DA73E !important;
    color: white !important;
}

.color-picker {
    transition: background-color 0.25s cubic-bezier(0.4, 0, 1, 1) 0s;
}

.sortableHelper{
    z-index: 9999 !important;
}
.record-disabled {
    color: #909090 !important;
}
.icon-disabled {
    color: #c9c9c9 !important;
}

.cursor-grab {
    cursor: grab;
}
.cursor-grabbing {
    cursor: grabbing;
}
