



.imgContainer{
    display: inline-flex;
}

.line{
    height: 3em;
    border: solid lightgrey 1px;
    margin: 1em;
}

.logo{
    width: 13em;
    margin-bottom: 1.8em;

}
